
.browser_button {
    display: inline-block;
    width: 160px;
    height: 180px;
    text-align: center;
    padding-top: 145px;
    background-position: center 10px;
    background-repeat: no-repeat;
}

.browser_options{
    text-align: center;
    margin-top: 20px;
}

.g-brd-highlight-top {
    border-top-color: #009acb !important; }

.button--chrome {
    background-image: url(img/logo_chrome_128x128.png);
}
.button--firefox {
    background-image: url(img/logo_firefox_128x128.png);
}
.button--edge {
    background-image: url(img/logo_edge_128x128.png);
}
.button--safari {
    background-image: url(img/logo_safari_128x128.png);
}
