/* Add here all your css styles (customizations) */
html {
    height: 100%;
}

body {
    background-color: #f4f4f4;
    font-size: 14px;
}

@media (max-width: 576px) {
    body {
        font-size: 12px;
    }
}

.content {
    flex: 1 0 auto !important; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

.footer {
    flex-shrink: 0 !important; /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: "Spectral", Helvetica, Arial, serif;
}

.bg-normal {
    background-color: #f4f4f4;
}

.bg-highlight {
    background-color: #009acb
}

.color-highlight {
    color: #009acb
}

.color-highlight-invert {
    color: #fff;
}

.brd-bg {
    border-color: #f4f4f4 !important;
}

.brd-highlight-bottom {
    border-bottom-color: #009acb !important;
}

.color-btn-icon {
    color: #888;
}

.badge {
    border-width: 1px;
    border-radius: 6px;
    padding: 4px 7px 4px 7px;
}

.badge-normal {
    border: 1px solid #dcdcdc;
    background-color: #f4f4f4;
    color: #212121;
    font-weight: normal;
}

.vat-highlight {
    border: 1px solid #c90502;
    background-color: #c90502;
    color: #fff;
}

.damage-highlight {
    border: 1px solid orange;
    background-color: orange;
    color: #fff;
}

.counter-badge-normal {
    border: 1px solid #009acb;
    background-color: #009acb;
    color: #ffffff;
    font-weight: normal;
}

.counter-badge-highlight {
    border: 1px solid orange;
    background-color: orange;
    color: #fff;
    font-weight: normal;
}




.div-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
}

.div-overlay div {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0px;
    right: 0px;
    font-size: 60px;
    color: #fff;
}

.upper-image-container {
    width: calc(50% - 4px);
    float: left;
    background-color: #fbfbfb;
    cursor: -webkit-zoom-in
}

.lower-image-container {
    width: calc((100% / 3) - 4px);
    float: left;
    background-color: #fbfbfb;
    cursor: -webkit-zoom-in;
}

.upper-image-container img, .lower-image-container img {
    width: 100%;
}

.align-right {
    text-align: right;
}

.damage-picture-thumbnail {
    width: 150px;
    height: 100px;
    float: right;
    cursor: -webkit-zoom-in;
}

.damage-picture-thumbnail-mobile {
    width: 380px;
    height: 320px;
    cursor: -webkit-zoom-in;
}

.damage-nopic {
    background-color: darkgrey;
}

.traffic-light {
    font-size: 15px;
    margin-right: 3px;
    align-self: center;
    color: #212121;
}

.traffic-light-red {
    color: #953034;
}

.traffic-light-orange {
    color: #f98500;
}

.traffic-light-green {
    color: #4aae00;
}

.filter-item-counter {
    width: 30px;
    padding: 0px 5px 0px 0px;
    text-align: right;
}

.filter-item-check {
    width: 30px;
    padding: 0px;
    text-align: right;
}

.filter-item-counter-active {
    background-color: #009acb;
    border-color: #009acb;
    color: #fff;
}

.filter-item:hover span {
    background-color: #009acb !important;
    color: #fff !important;
    border-color: #009acb !important;
}

.filter-summary-tags-list {
    display: flex;
    flex-wrap: wrap;
}

.filter-summary-tag {
    flex: 0 0 auto;
    border: 1px solid #dcdcdc;
    background-color: #f4f4f4;
    border-radius: 3px;
    margin: 4px 6px 4px 0;
}

.filter-summary-tag .close {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 10px 6px 6px;
    cursor: pointer;
    position: relative;
}

.filter-summary-tag .label {
    padding: 4px 4px 4px 8px;
}

.item {
    position: inherit;
    background-color: #fff;
    margin-bottom: 10px;
    border-bottom: 1px solid #dcdcdc;
    border-left: 5px solid white;
    cursor: pointer;
}

.item--hover:hover {
    /*border-left: 5px solid #009acb;*/
}

@media (min-width: 1600px) {
    .item-active {
        border-left: 5px solid #009acb;
    }
}

.item-disabled {
    opacity: 0.5;
}


.ReactModal__Body--open {
    overflow: hidden;
}

.dropdown div {
    padding: 3px;
}

.dropdown-no-border div {
    border: none;
   /*padding-right: 25 px;*/
}

.daterange div {
    display: block !important;
}

.interest-arrow {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid #009acb;
    transform: rotate(135deg);
}

.interest-container i {
    color: white;
}

.interest-container:hover i {
    color: rgba(255, 255, 255, 0.5);
}

.expectation {
    background-color: #f7f7f7;
    padding: 2px 0px 2px 0px;
}

.veh-description {
    /*background-color: #f7f7f7;*/
}

.veh-description {
    /*background-color: #ceeef8;*/
    /*background-color: #f7f7f7;*/
    color: darkgreen;
}

.veh-description-icon-important {
    color: #d23901;
}

.veh-description-icon-normal {
    color: darkgreen;
}

.container-important {
    border: 1px solid #d23901;
    color: #d23901;
 /*background-color: #f4db2a;*/

}

.container-normal {
    border: 1px solid darkgreen;
    color: darkgreen;

}

.tabs {
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.tabs .tab-links {
    margin: 0;
    padding: 0;
    border-bottom: 2px solid #009acb;
    height: 40px;
    position: relative;
    top: 5px;

}

.tabs .tab-link {
    padding: 0 30px;
    cursor: pointer;
    display: inline-block;
    outline: none !important;
    position: relative;
    height: 44px;
    background-color: white;
    line-height: 41px;
    top: -6px;

}

.tabs .tab-link-active {
    background-color: #009acb;
    color: white;
}

.tabs .content {
    padding: 15px;
    border-top: 1px solid transparent;
    border-top: 1px solid transparent;
}




@media (min-width: 768px) {
    .u-timeline-v2-wrap::before {
        left: 17%;
        margin-left: -4px;

    }
}

@media (min-width: 768px) {
    .u-info-v9-1 {
        position: relative;
    }

    .u-info-v9-1::before {
        position: absolute;
        top: 20px;
        left: 10%;
        width: 80%;
        border-top: 1px dotted #ddd;
        content: " ";
    }
}

.registration-description {
    font-size: 14px;
    color: #888888;
    padding: 20px;
    text-align: center;
    line-height: 18px;
}

.error {
    border: 1px solid red;
}

.loadingContainerOverlay {
    text-align: center;
    background: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100% !important;
    height: 100%;
}

.react-phone-number-input__row input {
    border-bottom: none !important;
    background: transparent !important;
}

.react-phone-number-input__row button {
    border-bottom: none !important;
}

.react-phone-number-input__icon {
    height: auto !important;
    border: none !important;
}

.react-phone-number-input-focus {
    border: 1px solid #009acb !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.box-shadow {
    -webkit-box-shadow: 0px -13px 46px -25px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px -13px 46px -25px rgba(0, 0, 0, 0.75);
    box-shadow: 0px -13px 46px -25px rgba(0, 0, 0, 0.75);
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    border: 1px solid #aaa;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #f7f7f7;
    /*color: #FFFFFF;*/
}

.text-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.center-div {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: #ccc;
    border-radius: 3px;
}

.center-right {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
}

.center-left {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
}

.image-container-overlay {
    position: relative;
}

.image-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.clickable {
    cursor: pointer;
}

@media (min-width: 768px) {
    div.sticky {
        position: sticky;
        position: -webkit-sticky;
        top: 70px;
        right: 0px;
        z-index: 100;
    }

    div.stickyHeader {
        position: fixed;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 100;
    }
}

@media (max-width: 768px) {
    div.sticky-md-down {
        position: sticky;
        position: -webkit-sticky;
        top: 0px;
        right: 0px;
        z-index: 50;
    }

    div.stickyHeader {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        z-index: 100;
    }

    .ReactModal__Content {
        padding: 5px !important;
    }
}

@media (max-width: 576px) {
    .h5 {
        font-size: 14px;
        line-height: 1;
    }
}

.bottom-shadow {
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.4);
}

.click-text {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 1599px) {
    .hide-xl-down {
        display: none !important;
    }
}

@media (min-width: 1600px) {
    .container {
        width: 1570px;
    }

    .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12 {
        /*float: left;*/
    }

    .col-xxl-12 {
        width: 100%;
    }

    .col-xxl-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%;
        width: 91.66666667%;
    }

    .col-xxl-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%;
        width: 83.33333333%;
    }

    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
        width: 75%;
    }

    .col-xxl-8 {
        flex: 0 0 66.66666667%;
        max-width: 91.66666667%;
        width: 91.66666667%;
    }

    .col-xxl-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%;
        width: 58.33333333%;
    }

    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
        width: 50%;
    }

    .col-xxl-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%;
        width: 41.66666667%;
    }

    .col-xxl-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
        width: 33.33333333%;
    }

    .col-xxl-3 {
        width: 25%;

        flex: 0 0 25%;
        max-width: 25%;
        width: 25%;
    }

    .col-xxl-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%;
        width: 16.66666667%;
    }

    .col-xxl-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%;
        width: 8.33333333%;
    }

    .col-xxl-pull-12 {
        right: 100%;
    }

    .col-xxl-pull-11 {
        right: 91.66666667%;
    }

    .col-xxl-pull-10 {
        right: 83.33333333%;
    }

    .col-xxl-pull-9 {
        right: 75%;
    }

    .col-xxl-pull-8 {
        right: 66.66666667%;
    }

    .col-xxl-pull-7 {
        right: 58.33333333%;
    }

    .col-xxl-pull-6 {
        right: 50%;
    }

    .col-xxl-pull-5 {
        right: 41.66666667%;
    }

    .col-xxl-pull-4 {
        right: 33.33333333%;
    }

    .col-xxl-pull-3 {
        right: 25%;
    }

    .col-xxl-pull-2 {
        right: 16.66666667%;
    }

    .col-xxl-pull-1 {
        right: 8.33333333%;
    }

    .col-xxl-pull-0 {
        right: auto;
    }

    .col-xxl-push-12 {
        left: 100%;
    }

    .col-xxl-push-11 {
        left: 91.66666667%;
    }

    .col-xxl-push-10 {
        left: 83.33333333%;
    }

    .col-xxl-push-9 {
        left: 75%;
    }

    .col-xxl-push-8 {
        left: 66.66666667%;
    }

    .col-xxl-push-7 {
        left: 58.33333333%;
    }

    .col-xxl-push-6 {
        left: 50%;
    }

    .col-xxl-push-5 {
        left: 41.66666667%;
    }

    .col-xxl-push-4 {
        left: 33.33333333%;
    }

    .col-xxl-push-3 {
        left: 25%;
    }

    .col-xxl-push-2 {
        left: 16.66666667%;
    }

    .col-xxl-push-1 {
        left: 8.33333333%;
    }

    .col-xxl-push-0 {
        left: auto;
    }

    .col-xxl-offset-12 {
        margin-left: 100%;
    }

    .col-xxl-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xxl-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xxl-offset-9 {
        margin-left: 75%;
    }

    .col-xxl-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xxl-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xxl-offset-6 {
        margin-left: 50%;
    }

    .col-xxl-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xxl-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xxl-offset-3 {
        margin-left: 25%;
    }

    .col-xxl-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xxl-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xxl-offset-0 {
        margin-left: 0;
    }

    .visible-xxl {
        display: block !important;
    }

    table.visible-xxl {
        display: table;
    }

    tr.visible-xxl {
        display: table-row !important;
    }

    th.visible-xxl, td.visible-xxl {
        display: table-cell !important;
    }

    .visible-xxl-block {
        display: block !important;
    }

    .visible-xxl-inline {
        display: inline !important;
    }

    .visible-xxl-inline-block {
        display: inline-block !important;
    }

    .hidden-xxl {
        display: none !important;
    }
}

@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"] {
        font-size: 16px;
    }
}

#vehicle_search_popover {
    background-color: #009acb;
}

.text-color-default {
    color: #777 !important;
}

.notifications-progress-bar {
    background: #009acb;
}

.recharts-wrapper {
    width: auto !important;
}

#statistics-container hr {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.stats-item:hover div {
    background-color: #009acb !important;
    color: #fff !important;
    border-color: #009acb !important;
}

.multicolumn {
    -webkit-column-count: 2 !important;
    -moz-column-count: 2 !important;
    column-count: 2 !important;
    -webkit-column-width: 100px !important;
    -moz-column-width: 100px !important;
    column-width: 100px !important;
    flex: 1;
}

.loading-indicator-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.lds-ripple {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 58px;
    height: 58px;
    padding: 50px;

}
.lds-ripple div {
    position: absolute;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    margin: 23px 0 0 23px;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}


.lds-ring {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;

}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    margin: 0px;
    padding-top: 3px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.lds-ring-black div {
    border-color: #000 transparent transparent transparent;
}

.lds-ring-white div {
    border-color: #fff transparent transparent transparent;
}

.text-light-gr {
    color: #6f6f6f;
}


@media (min-width: 768px) {
    .font-size-13-important {
        font-size: 13px !important;
    }
}


.animationTest {
    transition: all 100ms ease-in-out;
}
